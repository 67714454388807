html {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-weight: 300;
    overflow: hidden;
    font-family: sans-serif;
  }
  
  body,
  #root {
    height: 100%;
  }
  
  /* .UnderConstruction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%;
  } */
  
  /* .UnderConstruction-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  } */
  
  /* .UnderConstruction-container {
    margin-top: -50px;
    width: 100%;
    max-width: 800px;
    position: relative;
  }
  @media (max-width: 700px) {
    .UnderConstruction-container {
      margin-top: -120px;
    }
  } */
  
  /* .UnderConstruction-logo-container {
    text-align: center;
  } */
  
  /* .UnderConstruction-logo {
    width: 130px;
    filter: invert(1);
    opacity: .8;
  } */
  
  /* .UnderConstruction-title {
    margin: 0 auto;
    margin-top: 18px;
    font-size: 40px;
    text-align: center;
  }
  @media (max-width: 700px) {
    .UnderConstruction-title {
      font-size: 30px;
    }
  } */
  
  /* .UnderConstruction-description {
    margin: 0 auto;
    margin-top: 18px;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
  }
  @media (max-width: 700px) {
    .UnderConstruction-description {
      font-size: 16px;
    }
  } */
  
  /* .UnderConstruction-social-networks {
    width: 100%;
    position: fixed;
    text-align: center;
    bottom: 30px;
  } */
  
  /* .UnderConstruction-social-networks-link {
    color: white;
    opacity: 1;
    display: inline-block;
    text-decoration: none;
    transition: all .3s ease-in;
    margin-right: 20px;
  }
  .UnderConstruction-social-networks-link:hover {
    opacity: 1;
    text-decoration: underline;
  } */
  /* .UnderConstruction-social-networks-link:last-child {
    margin-right: 0;
  }
  
  .UnderConstruction-social-networks-image {
    width: 55px;
    filter: invert(1);
  } */
  